<template>
    <v-container>
      <h2>Dashboard</h2>
      <!-- Contenido del dashboard -->
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'AdminDashboard'
  };
  </script>
  
  <style scoped>
  /* Estilos para el Dashboard */
  </style>
  